<template>
  <b-modal
    id="approve-schedule"
    title="Verify Payment Schedule"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-container v-show="can('verify', 'payments')">
      <h5 class="text-dark font-weight-light">
        Are you sure you want to Verify this Payment Schedule?
      </h5>

      <hr />
      <b-row>
        <b-col class="text-dark">
          Total Farmers
        </b-col>
        <b-col>
          <span class="float-right">
            {{ schedule.farmers }}
          </span>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="text-primary">
          Amount
        </b-col>
        <b-col>
          <span class="font-weight-bold float-right">
            {{ format_num(schedule.total_amount) }}
          </span>
        </b-col>
      </b-row>
      <br/>

     
      <b-list-group >
        <b-list-group-item>
          <b-row>
            <b-col>
              Created by
            </b-col>
            <b-col class="float-right">
              {{ schedule.created_by }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="float-right">
            <small v-show="schedule.created_at"
          >on&nbsp;
          {{ time_format(schedule.created_at) }}
        </small>
        </b-list-group-item>
      </b-list-group>
      <br/>

      <b-row class="text-muted ">
        <b-col class="text-primary">
          Total Amount
        </b-col>
        <b-col>
          <span class="float-right text-success font-weight-bold">
            {{ currency }}{{ format_num(schedule.total_amount) }}
          </span>
        </b-col>
      </b-row>
      <hr />

      <div>
        <b-row v-show="payment">
          <b-col>
            <b-button
              class="float-left"
              @click="cancel"
              variant="outline-primary"
            >
              <small>Cancel</small>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="float-right"
              @click="approve_payment"
              variant="primary"
              v-analytics:click="['confirm_verify_payment',{element:'Verify Payment'}]"
            >
              <small>Verify Payment</small>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-container v-show="error">
        <b-alert class="justify-content-center" variant="danger"
          >Failed!!!</b-alert
        >
      </b-container>
      <b-container v-show="success">
        <b-alert class="justify-content-center" variant="success" show
          >Successful!!</b-alert
        >
      </b-container>
      <b-container v-show="loading">
        <b-row class="d-flex justify-content-center mb-3">
          <span class="font-weight-light text-muted"
            >Processing...&nbsp;&nbsp;</span
          >
          <b-spinner variant="secondary" label="Loading..."></b-spinner>
        </b-row>
      </b-container>
    </b-container>
  </b-modal>
</template>
<script>
import { approve_payment, cancel, time_format, format_num } from "./actions.js";
import { can } from "@/modules/auth/utils.js";

export default {
  props: {
    schedule: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed:{
    url(){
      return process.env.VUE_APP_BACKEND + "/api/v3/"+
      this.$store.state.project_id+this.$store.state.url_prefix;
    },
  },
  data() {
    return {
      currency: "UGX. ",
      loading: false,
      payment: true,
      success: false,
      error: false,
    };
  },
  methods: {
    approve_payment,
    cancel,
    time_format,
    can,
    format_num,
  },
  created() {},
};
</script>
