<template>
  <b-modal
    id="delete-schedule"
    title="Delete Schedule"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-container v-show="can('approve', 'payments')">
      <h5 class="text-dark font-weight-light">
        Are you sure you want to Delete this Payment Schedule?
      </h5>
      <hr />

      <b-row>
        <b-col class="text-dark">
          Total Farmers
        </b-col>
        <b-col>
          <span class="text-success float-right">
            {{ schedule.farmers }}
          </span>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="text-dark">
          Amount
        </b-col>
        <b-col>
          <span class="float-right">
            {{ (schedule.total_amount?schedule.total_amount:0).toLocaleString() }}
          </span>
        </b-col>
      </b-row>
      <hr />

      <b-container>
        <b-row v-if="schedule.authorized_at">
          <b-col class="pl-0">
            <span class="text-danger">Verified By</span>
          </b-col>
          <b-col>
            <b-row class="float-right text-align-right">
              {{ schedule.approved_by }}
            </b-row>
            <b-row class="text-muted float-right text-align-right">
              <small v-show="schedule.approved_at"
                >on&nbsp;
                {{ time_format(schedule.approved_at) }}
              </small>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row v-if="schedule.approved_at">
          <b-col class="pl-0">
            <span class="text-danger">Verified By</span>
          </b-col>
          <b-col>
            <b-row class="float-right text-align-right">
              {{ schedule.approved_by }}
            </b-row>
            <b-row class="text-muted float-right text-align-right">
              <small v-show="schedule.approved_at"
                >on&nbsp;
                {{ time_format(schedule.approved_at) }}
              </small>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <hr />

      <b-container>
        <b-row>
          <b-col class="pl-0">
            <span >Created By</span>
          </b-col>
          <b-col>
            <b-row class="float-right text-align-right">
              {{ schedule.created_by }}
            </b-row>
            <b-row class=" text-muted float-right text-align-right">
              <small v-show="schedule.created_at"
                >on&nbsp;
                {{ time_format(schedule.created_at) }}
              </small>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <hr />

      <b-row class="text-muted">
        <b-col class="text-primary">
          Amount
        </b-col>
        <b-col>
          <span class="float-right">
            {{ currency }}
            <span class=" text-success font-weight-bold">
              {{ (schedule.total_amount?schedule.total_amount:0).toLocaleString() }}
            </span>
          </span>
        </b-col>
      </b-row>
      <hr />


      <div v-show="!loading">
        <b-row>
            <b-col>
                <b-button 
                @click="delete_schedule" 
                variant="danger"
                class="float-right"
                :disabled="!can('verify', 'payments')"
                v-analytics:click="['confirm_delete_schedule',{element:'Remove Schedule'}]"
                >
                    <b-icon-trash></b-icon-trash>
                    Delete Schedule
                </b-button>
            </b-col>
          
        </b-row>
      </div>
      <b-container v-show="loading">
        <b-row class="d-flex justify-content-center mb-3">
          <span class="font-weight-light text-muted"
            >Processing...&nbsp;&nbsp;</span
          >
          <b-spinner variant="secondary" label="Loading..."></b-spinner>
        </b-row>
      </b-container>
      <b-container v-show="error">
        <b-alert class="justify-content-center" variant="danger"
          >Payment Failed!!!</b-alert
        >
      </b-container>

      <b-container v-show="success">
        <b-alert class="justify-content-center" variant="danger"
          >Successful!!!</b-alert
        >
      </b-container>
    </b-container>
  </b-modal>
</template>
<script>
import {
  delete_schedule,
  cancel,
  time_format,
} from "./actions.js";
import { can } from "@/modules/auth/utils.js";

export default {
  props: {
    schedule: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed:{
    url(){
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  data() {
    return {
      verified: false,
      pin: "",
      loading: false,
      success: false,
      error: false,
      currency: "UGX. ",
      waited: 1,
    };
  },
  methods: {
    delete_schedule,
    cancel,
    time_format,
    can,
  },
  created() {
    this.verified = false;
  },
};
</script>
