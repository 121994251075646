import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";

function send_verification() {
  this.loading = true;

  axiosApiInstance({
    url: this.url+"/payments/send_otp/",
    method: "post",
  })
    .then((res) => {
      console.log(res.status);
      this.pin='';
      this.loading = false;
      this.verified = true;
      this.waited = 1;
      setTimeout(() => {
        this.waited = 0;
        
      }, 120000);
    })
    .catch((error) => {
      this.loading = false;
      this.pin='';
      this.error = true;
      this.verified = false;
      console.log(error);
    });
}
function authorize_payment() {
  this.loading=true;
  let data = {
    pin: this.pin,
  };
  axiosApiInstance({
    url:this.url+"/payments/schedules/authorize/" +
      this.schedule.id,
    method: "put",
    data,
  })
    .then((res) => {
      console.log(res.status);
      this.loading = false;
      this.verified = false;
      this.waited = 1;
      this.$emit('Success');
      setTimeout(() => {
        this.$router.push('/payments/schedules');
        this.$bvModal.hide('authorize-pay');
        
      }, 2000);
    })
    .catch((error) => {
      this.loading = false;
      this.error = true;
      setTimeout(() => {
        this.error=false
      }, 2000);
      this.verified = false;
      console.log(error);
    });
}
function format_num(num){
  return num?Number(num).toLocaleString():'-'
}

function cancel() {
  this.loading = false;
  this.success = false;
  this.error = false;
  this.payment =true;
  this.$bvModal.hide("authorize-pay");
}

function time_format(time) {
  return moment(time,"YYYY-MM-DD HH:mm:ss").format("LL");
}
export { 
  send_verification, 
  authorize_payment, 
  cancel, time_format,
  format_num
};
