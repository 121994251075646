
import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";


function delete_schedule() {
  this.loading=true;
  axiosApiInstance({
    url:this.url+"/payments/schedules/" +
      this.schedule.id,
    method: "delete",
  })
    .then((res) => {
      console.log(res.status);
      this.loading = false;
      this.verified = false;
      this.waited = 1;
      this.$emit('Success');
      setTimeout(() => {
        this.$router.push('/payments/schedules');
        this.$bvModal.hide('delete-schedule');
        
      }, 2000);
    })
    .catch((error) => {
      this.loading = false;
      this.error = true;
      setTimeout(() => {
        this.error=false
      }, 2000);
      this.verified = false;
      console.log(error);
    });
}
function format_num(num){
  return num?Number(num).toLocaleString():'-'
}

function cancel() {
  this.loading = false;
  this.success = false;
  this.error = false;
  this.payment =true;
  this.$bvModal.hide("delete-schedule");
}

function time_format(time) {
  return moment(time,"YYYY-MM-DD HH:mm:ss").format("LL");
}
export { 
  delete_schedule, 
  cancel, time_format,
  format_num
};
