<template>
  <b-modal
    id="authorize-pay"
    title="Pay Now"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-container v-show="can('approve', 'payments')">
      <h5 class="text-dark font-weight-light">
        Are you sure you want to Approve this Payment Schedule?
      </h5>
      <hr />

      <b-row>
        <b-col class="text-dark">
          Total Farmers
        </b-col>
        <b-col>
          <span class="text-success float-right">
            {{ schedule.farmers }}
          </span>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="text-dark">
          Amount
        </b-col>
        <b-col>
          <span class="float-right">
            {{ (schedule.total_amount?schedule.total_amount:0).toLocaleString() }}
          </span>
        </b-col>
      </b-row>
      <br />

      <b-list-group >
        <b-list-group-item>
          <b-row>
            <b-col>
              <span class="text-danger">Verified By</span>
            </b-col>
            <b-col class="float-right">
              {{ schedule.approved_by }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="float-right">
            <small class="text-muted" v-show="schedule.approved_at"
          >on&nbsp;
          {{ time_format(schedule.approved_at) }}
        </small>
        </b-list-group-item>
      </b-list-group>
      <br/>

      <b-list-group >
        <b-list-group-item>
          <b-row>
            <b-col>
              Created by
            </b-col>
            <b-col class="float-right">
              {{ schedule.created_by }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="float-right">
            <small class="text-muted" v-show="schedule.created_at"
          >on&nbsp;
          {{ time_format(schedule.created_at) }}
        </small>
        </b-list-group-item>
      </b-list-group>
      <br/>
      
      <b-row class="text-muted">
        <b-col class="text-primary">
          Total Amount
        </b-col>
        <b-col>
          <span class="float-right">
            {{ currency }}
            <span class=" text-success font-weight-bold">
              {{ (schedule.total_amount?schedule.total_amount:0).toLocaleString() }}
            </span>
          </span>
        </b-col>
      </b-row>
      <hr />

      <div>
        <b-row v-show="verified && !loading">
          <b-col lg="12">
            <b-form-group
              class="p-3"
              label="Enter Security Code"
              label-for="code"
              description="Check your sms for a pin from EzyAgric."
            >
              <b-form-input
                v-model="pin"
                type="text"
                class="password"
                :disabled="!can('approve', 'payments')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <hr />
          <b-col>
            <b-button
              class="float-left"
              @click="send_verification"
              :disabled="waited == 1"
              variant="outline-primary"
            >
              <small>Request Another Pin</small>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="float-right"
              @click="authorize_payment"
              variant="success"
              v-analytics:click="['pay_out_schedule',{element:'Pay Now'}]"
            >
              <b-icon-check></b-icon-check><small>Pay Now</small>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-show="!verified && !loading">
        <b-row>
          <b-button @click="send_verification" 
          variant="outline-success" 
          v-analytics:click="['request_payment_pin',{element:'Request Pin'}]">
            <b-icon-envelope></b-icon-envelope>
            Request Pin
          </b-button>
        </b-row>
      </div>
      <b-container v-show="loading">
        <b-row class="d-flex justify-content-center mb-3">
          <span class="font-weight-light text-muted"
            >Processing...&nbsp;&nbsp;</span
          >
          <b-spinner variant="secondary" label="Loading..."></b-spinner>
        </b-row>
      </b-container>
      <b-container v-show="error">
        <b-alert class="justify-content-center" variant="danger"
          >Payment Failed!!!</b-alert
        >
      </b-container>

      <b-container v-show="success">
        <b-alert class="justify-content-center" variant="danger"
          >Successful!!!</b-alert
        >
      </b-container>
    </b-container>
  </b-modal>
</template>
<script>
import {
  authorize_payment,
  send_verification,
  cancel,
  time_format,
} from "./actions.js";
import { can } from "@/modules/auth/utils.js";

export default {
  props: {
    schedule: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed:{
    url(){
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  data() {
    return {
      verified: false,
      pin: "",
      loading: false,
      success: false,
      error: false,
      currency: "UGX. ",
      waited: 1,
    };
  },
  methods: {
    authorize_payment,
    send_verification,
    cancel,
    time_format,
    can,
  },
  created() {
    this.verified = false;
  },
};
</script>
<style scoped>

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
.password{
  font-family:'password';
}
</style>
