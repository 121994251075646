import { bus } from "@/main.js";
import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";

function time_format(time) {
  return moment(time,"YYYY-MM-DD HH:mm:ss").format("LL");
}
function format_num(num){
  return num?Number(num).toLocaleString():'0'
}

function approve_payment() {
  this.loading = true;
  this.payment = false;
  axiosApiInstance({
    url:this.url+"/payments/schedules/approve_schedule/" +
      this.schedule.id,
    method: "put",
  }).then((res) => {
      console.log(res.status);
      this.loading = false;
      this.success = true;
      bus.$emit("Success", {});
      setTimeout(() => {
        this.success = false;
        this.loading = false;
        this.verified = false;
        this.error = false;
        this.payment =true;
        this.$router.push('/payments/schedules');
        this.$bvModal.hide("approve-schedule");
      }, 2000);
    }).catch((error) => {
      this.loading = false;
      this.error = true;
      this.success = false;
      this.payment =true;
      console.log(error);
    });
}
function cancel() {
  this.loading = false;
  this.success = false;
  this.error = false;
  this.payment =true;
  this.$bvModal.hide("approve-schedule");
}

export { 
  approve_payment, cancel,
  time_format, format_num
};
