<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">
        <b-link class="text-dark" to="/payments">PAYMENTS</b-link
        ><b-icon-chevron-right></b-icon-chevron-right>
        <b-link class="text-dark" to="/payments/schedules">SCHEDULES</b-link>
        <b-icon-chevron-right />
        <a>{{ schedule.tag?schedule.tag:format_date(schedule.created_at) }}</a>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button v-if="can('create_schedule','payments')" squared variant="primary" @click="create_payment_schedule">
          <b-icon-plus></b-icon-plus> New Schedule
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <div class="row m-0">
        <div class="col">
          <div class="d-flex align-items-center">
            <!-- <select
              text="All produce"
              class="m-md-2 form-select form-control"
              variant="light"
            >
              <option value="">All produce</option>
              <option v-for="(crop, idx) in crops" :key="'c' + idx">{{
                crop
              }}</option>
            </select> -->
            <b-form class="ml-auto-">
              <b-form-input
                placeholder="search Name"
                type="search"
                class="search"
                v-model="searchfield"
              ></b-form-input>
            </b-form>
          </div>
        </div>
        <b-col>
          {{format_date(schedule.created_at)}}
        </b-col>
        <div class="col-auto ">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="reload">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" v-print="'#ScheduleTable'">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <!-- <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button> -->
            <download-excel
              class="btn btn-opt  primary"
              :data="items"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="text-center" v-show="loading">
        <b-row class="d-flex justify-content-center mb-3">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
          ></b-spinner>
        </b-row>
      </div>
      <div v-show="items.length > 0">
        <b-table
          id="ScheduleTable"
          :fields="visible_fields"
          bordered
          :items="items"
          :filter="searchfield"
          sticky-header="60vh"
          head-variant="light"
          @row-clicked="view_user"
          hover
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
          table-class="w100"
        >
          <template #cell(name)="data">
            <b-avatar icon="person">{{ data.item.name[0] }}</b-avatar>
            {{ data.item.name }}
          </template>
          <template #cell(usertype)="data">
            <usertype-pill :user_type="data.item.user_type">
            </usertype-pill>
          </template>
          <template #cell(amount_paid)="data">
            {{ data.item.amount.toLocaleString() }}
          </template>

          <template #cell(account)="data">
            {{ data.item.account }}
          </template>
          <template #cell(sending)="data">
            <span v-text="get_sending_charge(data.item.payment,data.item.ac_number,data.item.method).toLocaleString()"
            >
            </span>
          </template>
          <template #cell(withdrawal)="data">
            <span v-text="get_withdrawal_charge(data.item.payment,data.item.account,data.item.method).toLocaleString()"
            >
            </span>
          </template>
          <template #cell(withdrawal_tax)="data">
            <span v-text="get_tax_charge(data.item.payment,data.item.ac_number,data.item.method).toLocaleString()">
            
            </span>
          </template>
          
        </b-table>
        <hr />
      </div>
      <b-container>
        <b-row>
          <b-col>
            <h4>
              <b-icon-exclamation-circle
                v-show="schedule.approval_status == 'pending verification'"
                variant="danger"
              >
              </b-icon-exclamation-circle>
              <b-icon-exclamation-circle-fill
                v-show="schedule.approval_status == 'pending approval'"
                variant="warning"
              >
              </b-icon-exclamation-circle-fill>
              <b-icon-check-circle-fill
                v-show="schedule.approval_status == 'fully paid'"
                variant="success"
              >
              </b-icon-check-circle-fill>
              <b-icon-circle
                v-show="schedule.approval_status == 'initialized'"
                variant="primary"
              >
              </b-icon-circle>
              <b-icon-check-circle
                v-show="schedule.approval_status == 'partially paid'"
                variant="primary"
              ></b-icon-check-circle>
              &nbsp;&nbsp;<small class="text-capitalize font-weight-bold">{{
                schedule.approval_status
              }}</small>
            </h4>
          </b-col>

          <b-col class=" float-right ml-auto" v-show="schedule.total_amount">
            <h6><small class="text-muted">Total Farmers</small></h6>
            <h4 class="font-weight-bold">
              {{ schedule.farmers ? schedule.farmers.toLocaleString() : 0 }}
            </h4>
          </b-col>

          <b-col class="float-right ml-auto" v-show="schedule.total_amount">
            <h6><small>Amount</small></h6>
            <h4 class="font-weight-bold">
              {{ currency }}&nbsp;
              {{
                schedule.total_amount
                  ? schedule.total_amount.toLocaleString()
                  : 0
              }}
            </h4>
          </b-col>
          <b-col class="float-right ml-auto" v-show="schedule.total_amount">
            <h6><small>Total Amount</small></h6>
            <h4 class="font-weight-bold text-success">
              {{ currency }}&nbsp;
              {{
                total(items)
              }}
            </h4>
          </b-col>
        </b-row>
        <b-row class="border-top">
          <b-col  class="pt-3" v-if="schedule.approval_status == 'pending approval' ||
          schedule.approval_status == 'pending verification'
          ">
            <b-button
              :disabled="!can('approve', 'payments')"
              class="float-left"
              variant="outline-danger"
              v-b-modal.delete-schedule
              v-analytics:click="['remove_schedule',{element:'Remove Schedule'}]"
            >
              <b-icon-trash></b-icon-trash>
              Remove Schedule
            </b-button>
          </b-col>
          <b-col class="float-left ml-auto" v-show="schedule.total_amount">
            <h6><small>Sending Charge</small></h6>
            <h4 class="font-weight-bold text-secondary">
              {{ currency }}&nbsp;
              {{ total_sending(items,'amount').toLocaleString() }}
            </h4>
          </b-col>
          <!-- v-if="schedule.include_withdrawal" -->
          <b-col
            v-if="schedule.include_withdrawal"
            class="float-left ml-auto"
            v-show="schedule.total_amount"
          >
            <h6><small>Withdrawal Charge</small></h6>
            <h4 class="font-weight-bold">
              {{ currency }}&nbsp;
              {{ total_withdrawal(items).toLocaleString() }}
            </h4>
          </b-col>
          <!-- v-if="schedule.include_tax" -->
          <b-col
            v-if="schedule.include_tax"
            class="float-left ml-auto"
            v-show="schedule.total_amount"
          >
            <h6><small>Withdrawal tax</small></h6>
            <h4 class="font-weight-bold">
              {{ currency }}&nbsp;
              {{ total_tax(items).toLocaleString() }}
            </h4>
          </b-col>

          <b-col class="pt-3" v-show="schedule.approval_status == 'pending verification'">
            <b-button
              class="float-right "
              :disabled="!can('verify', 'payments')"
              variant="outline-primary"
              v-analytics:click="['verify_schedule',{element:'Verify Payments'}]"
              v-b-modal.approve-schedule
            >
              Verify Payment
            </b-button>
          </b-col>

          <b-col  class="pt-3" v-show="schedule.approval_status == 'pending approval'">
            <b-button
              :disabled="!can('approve', 'payments')"
              class="float-right"
              variant="success"
              v-b-modal.authorize-pay
              v-analytics:click="['approve_payment',{element:'Approve Payment'}]"
            >
              <b-icon-check></b-icon-check>
              Approve Payment
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <b-container v-show="schedule.approval_status == 'pending authorization'">
      </b-container>

      <b-container
        v-show="
          schedule.approval_status == 'fully paid' ||
            schedule.approval_status == 'partially paid'
        "
      >
      </b-container>

      <add-payment-schedule
        @SuccessfulPayment="reload()"
      ></add-payment-schedule>
      <approve-schedule
        @Success="reload"
        :schedule="schedule"
      ></approve-schedule>
      <delete-schedule
        @Success="reload"
        :schedule="schedule"
      ></delete-schedule>
      <authorize-schedule
        @Success="reload"
        :schedule="schedule"
      ></authorize-schedule>
      <farmer-modal @Success="reload" :schedule="schedule"></farmer-modal>
    </div>
  </div>
</template>

<script>
import {
  get_schedule_data,
  get_charges,
  get_tax_charge,
  get_withdrawal_charge,
  get_sending_charge,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
  format_date,
} from "./actions.js";

import { can } from "@/modules/auth/utils.js";
import { bus } from "@/main.js";
import AddPaymentSchedule from "@/components/add_payment_schedule/AddPaymentSchedule.vue";
import ApproveSchedule from "./components/verify_payments/VerifySchedule.vue";
import AuthorizeSchedule from "./components/approve_payments/ApproveSchedule.vue";
import DeleteSchedule from "./components/delete_schedule/DeleteSchedule.vue";
import UsertypePill from "../../components/usertype_pill/UsertypePill.vue";
import FarmerModal from "@/components/farmer_modal/Farmer.vue";

export default {
  components: {
    AddPaymentSchedule,
    ApproveSchedule,
    AuthorizeSchedule,
    FarmerModal,
    DeleteSchedule,
    UsertypePill,
  },
  computed: {
    url(){
      return process.env.VUE_APP_BACKEND + "/api/v3/"+this.$store.state.project_id+this.$store.state.url_prefix;
    },
    visible_fields(){
      let exclusions = [];
      this.include_withdrawal?0:exclusions.push('withdrawal');
      this.include_tax?0:exclusions.push('withdrawal_tax');
      return this.fields.filter(field=>!exclusions.includes(field.key))
    },
  },
  data() {
    return {
      searchfield: "",
      fields: [
        { key: "tag", label: "Ref",stickyColumn:true },
        { key: "farmer_name", label: "Name",stickyColumn:true, isRowHeader: true  },
        { key:"usertype",label:""},
        { key: "payment_method", label: "Method" },
        { key: "account", label: "A/c number" },

        { key: "ac_name", label: "A/c name" },
        
        { key: "sending", label: "Sending Charge",  },
        { key: "withdrawal", label:"Withdrawal Charge", },
        { key: "withdrawal_tax", label:"Withdrawal Tax" },
        { key: "amount_paid", label: "Amount", },

      ],
      items: [],
      isAdding: false,
      schedule_time: "",
      schedule: {},
      sending:[],
      withdrawal:[],
      tax:[],
      loading: false,
      crops: [],
      currency: "UGX",
      money:"money",
      account:"account",
      include_withdrawal:0,
      include_tax:0
    };
  },
  methods: {
    format_date,
    get_schedule_data,
    can,
    get_charges,
    get_tax_charge,
    get_withdrawal_charge,
    get_sending_charge,
    total,
    total_withdrawal,
    total_amount,
    total_sending,
    total_tax,
    create_payment_schedule() {
      bus.$emit("add-payment-schedule", { load: true });
    },
    reload() {
      this.get_schedule_data();
    },
    view_user(item) {
      bus.$emit("farmer-view", {
        name: item.farmer_name,
        data: item,
        farmer_id: item.user_id ? item.user_id : item.farmer_id,
        tab: "payments",
      });
    },
  },
  mounted() {
    this.get_schedule_data();
    this.get_charges();
  },
};
</script>

<style scoped>
.no-decorate {
  text-decoration: none;
}
.w100{
  max-width: 100%;
}
.table tbody .edit {
  padding:8px 2px !important;
}
</style>
